import BaseService from './BaseService';

class ReconhecimentoFacial extends BaseService {

  cadastrarFotoReconhecimentoFacial(idUsuario, foto) {

    const url = `/reconhecimentofacial/cadastrar/foto/usuario/${idUsuario}`;

    const requestInfo = {
      method: 'post',
      url,
      data: foto,
      headers: {
        'Content-type': 'text/plain',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  carregarFotosReconhecimentoFacial(idUsuario) {
    const url = `/reconhecimentofacial/usuario/${idUsuario}/fotos`;

    const requestInfo = {
      method: 'get',
      url,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  removerFotoReconhecimentoFacial(idFotoReconhecimentoFacial) {
    const url = `/reconhecimentofacial/remover/foto/${idFotoReconhecimentoFacial}`;

    const requestInfo = {
      method: 'delete',
      url,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }




}

export default ReconhecimentoFacial;
