import BaseService from './BaseService';

class AwsService extends BaseService {
  getPresignedUrl(url) {
    const requestInfo = {
      method: 'get',
      url: `/aws/presignedurl/${url}`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  getPresignedUrlUS(url) {
    const requestInfo = {
      method: 'get',
      url: `/aws/presignedurlus/${url}`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

	getPresignedUrlValidacao(url) {
    const requestInfo = {
      method: 'get',
      url: `/aws/validacao/presignedurl/${url}`,
      headers: {
        'Content-type': 'text/html',
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

}

export default AwsService;
