import React from 'react';
import { withRouter } from 'hoc/withRouter';
import { Link } from 'react-router-dom';

import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  FormGroup,
  Input,
  Label,
  Row,
  UncontrolledTooltip,
  Breadcrumb,
  BreadcrumbItem,
} from 'reactstrap';

import Loader from 'react-loader-spinner';
import NumberFormat from 'react-number-format';
import Select from 'react-select';

import Alert from 'views/components/Alert';
import LoaderModal from 'views/components/LoaderModal';
import ConfirmTypingModal from 'views/components/ConfirmTypingModal';

import AnosLetivosService from 'services/AnosLetivosService';
import EscolasService from 'services/EscolasService';
import TurmasService from 'services/TurmasService';

import TipoAvaliacao from 'assets/csv/tipos-avaliacao.json';
import CicloEnsino from 'assets/csv/ciclos-ensino.json';

class AlterarAvaliacoesTurma extends React.Component {

  constructor(props) {
    super(props);

    this.tiposAvaliacao = [
      TipoAvaliacao.NOTA,
      TipoAvaliacao.RELATORIO,
    ];

    this.state = {
      showAlert: false,

      descricoesAvaliacoes: [],

      mediaAprovacao: '6,0',
      mediaReprovacaoAntesRecuperacao: '2,5',
      temRecuperacao: true,
      pesoRecuperacao: '1,0',
      pesoMediaAvaliacoes: '2,0',
      mediaAprovacaoAposRecuperacao: '5,0',
      frequenciaMinima: '75,0',

      mediaAprovacaoState: 'primary',
      mediaReprovacaoAntesRecuperacaoState: 'primary',
      pesoRecuperacaoState: 'primary',
      mediaAprovacaoAposRecuperacaoState: 'primary',
    }

    this.anosLetivosService = new AnosLetivosService();
    this.escolasService = new EscolasService();
    this.turmasService = new TurmasService();

  }

  async componentDidMount() {
    const anoLetivo = await this.anosLetivosService
      .carregarAnoLetivo(this.props.match.params.idAnoLetivo);

    const escola = await this.escolasService
      .carregarEscola(this.props.match.params.idEscola);

    const turma = await this.turmasService
      .carregarTurma(this.props.match.params.idTurma);

    const _descricoesAvaliacoes = await this.turmasService
      .carregarDescricoesAvaliacoes(this.props.match.params.idTurma);

    const descricoesAvaliacoes = _descricoesAvaliacoes.filter((desc) => !desc.recuperacao);

    const tipoAvaliacaoSelecionada = this.tiposAvaliacao[turma.tipoAvaliacao];

    this.setState({
      anoLetivo,
      escola,
      turma,
      tipoAvaliacaoSelecionada,
      cursoSelecionado: turma.curso,
      descricoesAvaliacoes: descricoesAvaliacoes.map((desc) => {
        const a = {
          ...desc,
          peso: desc.peso ? this.converterParaString(desc.peso) : undefined,
        };
        return a;
      }),
    });

    if (tipoAvaliacaoSelecionada.value === TipoAvaliacao.NOTA.value) {
      this.setState({
        mediaAprovacao: this.converterParaString(turma.mediaAprovacao),
        mediaReprovacaoAntesRecuperacao: this.converterParaString(turma.mediaReprovacaoAntesRecuperacao),
        pesoRecuperacao: this.converterParaString(turma.pesoRecuperacao),
        pesoMediaAvaliacoes: this.converterParaString(turma.pesoMediaAvaliacoes),
        mediaAprovacaoAposRecuperacao: this.converterParaString(turma.mediaAprovacaoAposRecuperacao),
      });
    }
  }

  validarCampos() {
    let ret = true;

    if (!this.state.tipoAvaliacaoSelecionada) {
      this.setState({ tipoAvaliacaoState: 'danger' });
      ret = false;
    }

    this.state.descricoesAvaliacoes.forEach((desc) => {
      if (!desc.nome) {
        Object.assign(desc, { nomeState: 'has-danger' });
        ret = false;
      }
      if (this.state.tipoAvaliacaoSelecionada.value === TipoAvaliacao.NOTA.value && !desc.peso) {
        Object.assign(desc, { pesoState: 'has-danger' });
        ret = false;
      }
    });

    if (this.state.tipoAvaliacaoSelecionada.value === TipoAvaliacao.NOTA.value) {
      if (!this.state.mediaAprovacao) {
        this.setState({ mediaAprovacaoState: 'has-danger' });
        ret = false;
      }
      if (this.state.temRecuperacao) {
        if (!this.state.mediaReprovacaoAntesRecuperacao) {
          this.setState({ mediaReprovacaoAntesRecuperacaoState: 'has-danger' });
          ret = false;
        }
        if (!this.state.pesoRecuperacao) {
          this.setState({ pesoRecuperacaoState: 'has-danger' });
          ret = false;
        }
        if (!this.state.pesoMediaAvaliacoes) {
          this.setState({ pesoMediaAvaliacoesState: 'has-danger' });
          ret = false;
        }
        if (!this.state.mediaAprovacaoAposRecuperacao) {
          this.setState({ mediaAprovacaoAposRecuperacaoState: 'has-danger' });
          ret = false;
        }
      }

      if (!this.state.frequenciaMinima) {
        this.setState({ frequenciaMinimaState: 'has-danger' });
        ret = false;
      }
    }

    if ((this.state.cursoSelecionado.cicloEnsino === CicloEnsino.EJA.value
      || this.state.cursoSelecionado.cicloEnsino === CicloEnsino.CORRECAO_FLUXO.value)
      && this.state.tipoAvaliacaoSelecionada.value === TipoAvaliacao.NOTA.value) {
      if (!this.state.tipoDiarioSelecionado) {
        this.setState({ tipoDiarioState: 'danger' });
        ret = false;
      }
      if (!this.state.numeroAvaliacoesSelecionado) {
        this.setState({ numeroAvaliacoesState: 'danger' });
        ret = false;
      }
    }

    if (!ret) this.forceUpdate();

    return ret;
  }

  confirmarAlteracaoAvaliacaoTurma() {
    this.setState({ showConfirmModal: true });
  }

  async alterarAvaliacoesTurma(senha) {
    if (!this.validarCampos()) return;

    this.setState({
      showLoaderModal: true,
      showAlert: false,
      loaderModalText: 'Alterando avaliações da turma...',
    });

    const descricoesAvaliacoes = this.state.descricoesAvaliacoes
      .map((desc) => {
        const a = {
          ...desc,
          peso: desc.peso ? this.converterParaNumero(desc.peso) : undefined,
        };
        return a;
      });

    if (this.state.tipoAvaliacaoSelecionada.value === TipoAvaliacao.NOTA.value && this.state.temRecuperacao) {
      descricoesAvaliacoes.push({
        nome: 'Prova Final',
        peso: this.converterParaNumero(this.state.pesoRecuperacao),
        recuperacao: true,
      });
    }

    const turma = {
      id: this.state.turma.id,
      tipoAvaliacao: this.state.tipoAvaliacaoSelecionada.value,
      descricoesAvaliacoes,
      mediaAprovacao: this.state.tipoAvaliacaoSelecionada.value
        === TipoAvaliacao.NOTA.value
        ? this.converterParaNumero(this.state.mediaAprovacao) : undefined,
      temRecuperacao: this.state.tipoAvaliacaoSelecionada.value
        === TipoAvaliacao.NOTA.value ? this.state.temRecuperacao : undefined,
      mediaReprovacaoAntesRecuperacao: this.state.tipoAvaliacaoSelecionada.value
        === TipoAvaliacao.NOTA.value && this.state.temRecuperacao
        ? this.converterParaNumero(this.state.mediaReprovacaoAntesRecuperacao) : undefined,
      pesoRecuperacao: this.state.tipoAvaliacaoSelecionada.value
        === TipoAvaliacao.NOTA.value && this.state.temRecuperacao
        ? this.converterParaNumero(this.state.pesoRecuperacao) : undefined,
      pesoMediaAvaliacoes: this.state.tipoAvaliacaoSelecionada.value
        === TipoAvaliacao.NOTA.value && this.state.temRecuperacao
        ? this.converterParaNumero(this.state.pesoMediaAvaliacoes) : undefined,
      mediaAprovacaoAposRecuperacao: this.state.tipoAvaliacaoSelecionada.value
        === TipoAvaliacao.NOTA.value && this.state.temRecuperacao
        ? this.converterParaNumero(this.state.mediaAprovacaoAposRecuperacao) : undefined,
      senha,
    };

    

    try {
      await this.turmasService.alterarAvaliacoesTurma(turma);

      this.setState({
        showAlert: true,
        alertColor: 'success',
        alertMsg: 'Avaliações da turma alteradas com sucesso',
        showLoaderModal: false,
      });
    } catch (e) {
      this.setState({
        showLoaderModal: false,
        showAlert: true,
        alertColor: 'danger',
        alertMsg: e || 'Erro ao alterar avaliações da turma',
      });
    }
  }

  removerDescricaoAvaliacao(descricaoAvaliacao) {
    if (this.state.descricoesAvaliacoes.length === 1) return;
    const descricoesAvaliacoes = this.state.descricoesAvaliacoes.filter(
      (descricaoAvaliacao2) => descricaoAvaliacao !== descricaoAvaliacao2,
    );
    this.setState({ descricoesAvaliacoes });
  }

  converterParaNumero = (valor) => parseFloat(valor.replace(',', '.'));

  converterParaString = (valor) => valor.toFixed(1).toString().replace('.', ',')

  conteudoPagina() {
    if (!this.state.tipoAvaliacaoSelecionada) {
      return <div align="center" style={{ margin: 50 }}>
        <Loader
          type="Oval"
          color="#34B5B8"
          height="50"
          width="50" />
      </div>
    }

    return (
      <>
        <Row>
          <Col>
            <Alert
              color={this.state.alertColor}
              isOpen={this.state.showAlert}
              toggle={() => { this.setState({ showAlert: false }); }}>
              {this.state.alertMsg}
            </Alert>
            <Breadcrumb>
              <BreadcrumbItem><Link to={`${this.props.layout}/anosletivos`}>Anos Letivos</Link></BreadcrumbItem>
              <BreadcrumbItem><Link to={`${this.props.layout}/anosletivos/${this.state.anoLetivo.id}`}>Ano letivo {this.state.anoLetivo.ano}</Link></BreadcrumbItem>
              <BreadcrumbItem><Link to={`${this.props.layout}/anosletivos/${this.state.anoLetivo.id}/escola/${this.state.escola.id}`}>{this.state.escola.nome}</Link></BreadcrumbItem>
              <BreadcrumbItem><Link to={`${this.props.layout}/anosletivos/${this.state.anoLetivo.id}/escola/${this.state.escola.id}/turmas/edicao/${this.state.turma.id}`}>{this.state.turma.nome}</Link></BreadcrumbItem>
              <BreadcrumbItem active>Alterar avaliações</BreadcrumbItem>
            </Breadcrumb>
            <Card>
              <CardHeader>
                <CardTitle tag="h4">Alterar avaliações</CardTitle>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col md="2">
                    <FormGroup className={'has-label has-danger'}>
                      <label>Tipo da avaliação *</label>
                      <Select
                        noOptionsMessage={() => 'Nenhuma entrada'}
                        className={`react-select primary ${this.state.tipoAvaliacaoState}`}
                        classNamePrefix="react-select"
                        value={this.state.tipoAvaliacaoSelecionada}
                        onChange={(event) => {
                          if (this.state.anoLetivo.ente.estado.uf === 'PB') {
                            this.setState({
                              mediaAprovacao: '7,0',
                              mediaReprovacaoAntesRecuperacao: '2,5',
                              pesoRecuperacao: '4,0',
                              pesoMediaAvaliacoes: '6,0',
                              mediaAprovacaoAposRecuperacao: '5,0',
                            });
                          } else {
                            this.setState({
                              mediaAprovacao: '6,0',
                              mediaReprovacaoAntesRecuperacao: '2,5',
                              temRecuperacao: true,
                              pesoRecuperacao: '1,0',
                              pesoMediaAvaliacoes: '2,0',
                              mediaAprovacaoAposRecuperacao: '5,0',
                            });
                          }

                          const descricoesAvaliacoes = [];

                          if (event.value === TipoAvaliacao.RELATORIO.value) {
                            if (this.state.uf !== 'PB') {
                              descricoesAvaliacoes.push({ nome: '1º semestre' });
                              descricoesAvaliacoes.push({ nome: '2º semestre' });
                            } else {
                              descricoesAvaliacoes.push({ nome: '1º bimestre' });
                              descricoesAvaliacoes.push({ nome: '2º bimestre' });
                              descricoesAvaliacoes.push({ nome: '3º bimestre' });
                              descricoesAvaliacoes.push({ nome: '4º bimestre' });
                            }
                          } else {
                            descricoesAvaliacoes.push({ nome: '1º bimestre', peso: '1,0', recuperacao: false });
                            descricoesAvaliacoes.push({ nome: '2º bimestre', peso: '1,0', recuperacao: false });
                            descricoesAvaliacoes.push({ nome: '3º bimestre', peso: '1,0', recuperacao: false });
                            descricoesAvaliacoes.push({ nome: '4º bimestre', peso: '1,0', recuperacao: false });
                          }

                          this.setState({
                            tipoAvaliacaoState: '',
                            tipoAvaliacaoSelecionada: event,
                            descricoesAvaliacoes,
                            temRecuperacao: event.value === TipoAvaliacao.NOTA.value,
                          });
                        }}
                        options={this.tiposAvaliacao}
                        placeholder="Tipo da avaliação..."
                      />
                      {this.state.tipoAvaliacaoState === 'danger' ? (
                        <label className="error">
                          Informe o tipo da avaliação
                        </label>
                      ) : null}
                    </FormGroup>
                  </Col>
                </Row>
                {
                  this.state.tipoAvaliacaoSelecionada && <div
                    aria-multiselectable={true}
                    className="card-collapse"
                    id="accordion"
                    role="tablist"
                  >
                    <Card className="card-plain">
                      <CardHeader role="tab">
                        <h5>
                          Configuração de {this.state.tipoAvaliacaoSelecionada.value
                            === TipoAvaliacao.RELATORIO.value ? 'relatórios dos alunos' : 'provas'}
                        </h5>
                      </CardHeader>
                      <CardBody>
                        {
                          this.state.descricoesAvaliacoes.map(
                            (descricaoAvaliacao, idx) => <Row key={idx}>
                              <Col md="3">
                                <FormGroup className={`has-label ${descricaoAvaliacao.nomeState}`}>
                                  <label>Nome * </label>
                                  <Input
                                    type="text"
                                    value={descricaoAvaliacao.nome}
                                    onChange={(event) => {
                                      Object.assign(descricaoAvaliacao,
                                        {
                                          nome: event.target.value,
                                          nomeState: 'primary',
                                        });
                                      this.forceUpdate();
                                    }}
                                  />
                                  {descricaoAvaliacao.nomeState === 'has-danger' ? (
                                    <label className="error">
                                      Informe o nome da avaliação.
                                    </label>
                                  ) : null}
                                </FormGroup>
                              </Col>
                              {
                                this.state.tipoAvaliacaoSelecionada.value
                                === TipoAvaliacao.NOTA.value && <Col md="1">
                                  <FormGroup className={`has-label ${descricaoAvaliacao.pesoState}`}>
                                    <label>Peso *</label>
                                    <Input
                                      value={descricaoAvaliacao.peso}
                                      style={{ textAlign: 'right' }}
                                      decimalSeparator=","
                                      decimalScale={1}
                                      format={this.formatarNumero}
                                      onValueChange={(val) => {
                                        Object.assign(descricaoAvaliacao,
                                          {
                                            peso: val.formattedValue,
                                            pesoState: 'primary',
                                          });
                                        this.forceUpdate();
                                      }}
                                      onBlur={() => {
                                        if (descricaoAvaliacao.peso
                                          && descricaoAvaliacao.peso.length === 1) {
                                          Object.assign(descricaoAvaliacao,
                                            {
                                              peso: `${descricaoAvaliacao.peso},0`,
                                              pesoState: 'primary',
                                            });
                                          this.forceUpdate();
                                        }
                                      }}
                                      tag={NumberFormat}
                                    />
                                    {descricaoAvaliacao.pesoState === 'has-danger' ? (
                                      <label className="error">
                                        Informe o peso da avaliação.
                                      </label>
                                    ) : null}
                                  </FormGroup>
                                </Col>
                              }
                              <Col md="2">
                                <br />
                                <>
                                  <Button
                                    id={`btnRemoverAvaliacao${idx}`}
                                    className="btn-icon"
                                    color="danger"
                                    size="sm"
                                    onClick={() => {
                                      this.removerDescricaoAvaliacao(descricaoAvaliacao);
                                    }}
                                    type="button">
                                    <i className="fa fa-times" />
                                  </Button>
                                  <UncontrolledTooltip placement="bottom" target={`btnRemoverAvaliacao${idx}`} delay={0}>
                                    Remover
                                  </UncontrolledTooltip>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                  {idx === this.state.descricoesAvaliacoes.length - 1
                                    && <>
                                      <Button
                                        id="adicionarAvaliacaoBtn"
                                        className="btn-icon"
                                        color="success"
                                        size="sm"
                                        onClick={() => {
                                          this.state.descricoesAvaliacoes.push({
                                            nome: '',
                                            peso: this.state.tipoAvaliacaoSelecionada.value
                                              === TipoAvaliacao.RELATORIO.value ? undefined : '',
                                            recuperacao: this.state
                                              .tipoAvaliacaoSelecionada.value
                                              === TipoAvaliacao.RELATORIO.value
                                              ? undefined : false,
                                          });
                                          this.forceUpdate();
                                        }}>
                                        <i className="fa fa-plus-circle" />
                                      </Button>
                                      <UncontrolledTooltip placement="bottom" target="adicionarAvaliacaoBtn" delay={0}>
                                        Adicionar {this.state.tipoAvaliacaoSelecionada.value
                                          === TipoAvaliacao.RELATORIO.value ? 'relatório' : 'prova'}
                                      </UncontrolledTooltip>
                                    </>
                                  }
                                </>
                              </Col>
                            </Row>,
                          )
                        }
                        <Row>
                        </Row>
                        {this.state.tipoAvaliacaoSelecionada.value === TipoAvaliacao.NOTA.value
                          && <Row>
                            <Col md="3">
                              <FormGroup className={`has-label ${this.state.mediaAprovacaoState}`}>
                                <label>Média de aprovação * </label>
                                <Input
                                  value={this.state.mediaAprovacao}
                                  style={{ textAlign: 'right' }}
                                  decimalSeparator=","
                                  decimalScale={1}
                                  format={this.formatarNumero}
                                  onValueChange={(val) => {
                                    this.setState({
                                      mediaAprovacao: val.formattedValue,
                                      mediaAprovacaoState: 'primary',
                                    });
                                  }}
                                  onBlur={() => {
                                    if (this.state.mediaAprovacao
                                      && this.state.mediaAprovacao.length === 1) {
                                      this.setState({
                                        mediaAprovacao: `${this.state.mediaAprovacao},0`,
                                      });
                                    }
                                  }}
                                  tag={NumberFormat}
                                />
                                {this.state.mediaAprovacaoState === 'has-danger' ? (
                                  <label className="error">
                                    Informe a média de aprovação.
                                  </label>
                                ) : null}
                              </FormGroup>
                            </Col>
                          </Row>
                        }
                        {this.state.tipoAvaliacaoSelecionada.value === TipoAvaliacao.NOTA.value
                          && <>
                            < Row >
                              <Col md="4">
                                <FormGroup check>
                                  <Label check>
                                    <Input type="checkbox"
                                      defaultChecked={this.state.temRecuperacao}
                                      onClick={(evt) => this.setState({ temRecuperacao: evt.target.checked })} />{' '}
                                    Tem prova final
                                    <span className="form-check-sign">
                                      <span className="check"></span>
                                    </span>
                                  </Label>
                                </FormGroup>
                              </Col>
                            </Row>
                            {this.state.temRecuperacao
                              && <>
                                <Row>
                                  <Col md="3">
                                    <FormGroup className={`has-label ${this.state.mediaReprovacaoAntesRecuperacaoState}`}>
                                      <label>Média de reprovação antes da
                                        prova final * </label>
                                      <Input
                                        value={this.state.mediaReprovacaoAntesRecuperacao}
                                        style={{ textAlign: 'right' }}
                                        decimalSeparator=","
                                        decimalScale={1}
                                        format={this.formatarNumero}
                                        onValueChange={(val) => {
                                          this.setState({
                                            mediaReprovacaoAntesRecuperacao: val.formattedValue,
                                            mediaReprovacaoAntesRecuperacaoState: 'primary',
                                          });
                                        }}
                                        onBlur={() => {
                                          if (this.state.mediaReprovacaoAntesRecuperacao
                                            && this.state.mediaReprovacaoAntesRecuperacao.length
                                            === 1) {
                                            this.setState({
                                              mediaReprovacaoAntesRecuperacao: `${this.state.mediaReprovacaoAntesRecuperacao},0`,
                                            });
                                          }
                                        }}
                                        tag={NumberFormat}
                                      />
                                      {this.state.mediaReprovacaoAntesRecuperacaoState === 'has-danger' ? (
                                        <label className="error">
                                          Informe a média de reprovação antes
                                          da prova final.
                                        </label>
                                      ) : null}
                                    </FormGroup>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col md="3">
                                    <FormGroup className={`has-label ${this.state.pesoRecuperacaoState}`}>
                                      <label>Peso da prova final *</label>
                                      <Input
                                        value={this.state.pesoRecuperacao}
                                        style={{ textAlign: 'right' }}
                                        decimalSeparator=","
                                        decimalScale={1}
                                        format={this.formatarNumero}
                                        onValueChange={(val) => {
                                          this.setState({
                                            pesoRecuperacao: val.formattedValue,
                                            pesoRecuperacaoState: 'primary',
                                          });
                                        }}
                                        onBlur={() => {
                                          if (this.state.pesoRecuperacao
                                            && this.state.pesoRecuperacao.length
                                            === 1) {
                                            this.setState({
                                              pesoRecuperacao: `${this.state.pesoRecuperacao},0`,
                                            });
                                          }
                                        }}
                                        tag={NumberFormat}
                                      />
                                      {this.state.pesoRecuperacaoState === 'has-danger' ? (
                                        <label className="error">
                                          Informe o peso da prova final.
                                        </label>
                                      ) : null}
                                    </FormGroup>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col md="3">
                                    <FormGroup className={`has-label ${this.state.pesoMediaAvaliacoesState}`}>
                                      <label>Peso da média das avaliações
                                        anteriores à prova final *</label>
                                      <Input
                                        value={this.state.pesoMediaAvaliacoes}
                                        style={{ textAlign: 'right' }}
                                        decimalSeparator=","
                                        decimalScale={1}
                                        format={this.formatarNumero}
                                        onValueChange={(val) => {
                                          this.setState({
                                            pesoMediaAvaliacoes: val.formattedValue,
                                            pesoMediaAvaliacoesState: 'primary',
                                          });
                                        }}
                                        onBlur={() => {
                                          if (this.state.pesoMediaAvaliacoes
                                            && this.state.pesoMediaAvaliacoes.length
                                            === 1) {
                                            this.setState({
                                              pesoMediaAvaliacoes: `${this.state.pesoMediaAvaliacoes},0`,
                                            });
                                          }
                                        }}
                                        tag={NumberFormat}
                                      />
                                      {this.state.pesoMediaAvaliacoesState === 'has-danger' ? (
                                        <label className="error">
                                          Informe o peso da média das avaliações
                                          anteriores à prova final.
                                        </label>
                                      ) : null}
                                    </FormGroup>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col md="3">
                                    <FormGroup className={`has-label ${this.state.mediaAprovacaoAposRecuperacaoState}`}>
                                      <label>Média de aprovação após prova final * </label>
                                      <Input
                                        value={this.state.mediaAprovacaoAposRecuperacao}
                                        style={{ textAlign: 'right' }}
                                        decimalSeparator=","
                                        decimalScale={1}
                                        format={this.formatarNumero}
                                        onValueChange={(val) => {
                                          this.setState({
                                            mediaAprovacaoAposRecuperacao: val.formattedValue,
                                            mediaAprovacaoAposRecuperacaoState: 'primary',
                                          });
                                        }}
                                        onBlur={() => {
                                          if (this.state.mediaAprovacaoAposRecuperacao
                                            && this.state.mediaAprovacaoAposRecuperacao.length
                                            === 1) {
                                            this.setState({
                                              mediaAprovacaoAposRecuperacao: `${this.state.mediaAprovacaoAposRecuperacao},0`,
                                            });
                                          }
                                        }}
                                        tag={NumberFormat}
                                      />
                                      {this.state.mediaAprovacaoAposRecuperacaoState === 'has-danger' ? (
                                        <label className="error">
                                          Informe a média de aprovação após a prova final.
                                        </label>
                                      ) : null}
                                    </FormGroup>
                                  </Col>
                                </Row>
                              </>
                            }
                          </>
                        }
                      </CardBody>
                    </Card>
                  </div>
                }
                <Row>
                  <Col className="text-left">
                    <Button color="primary" onClick={() => this.props.history.goBack()}>
                      Voltar
                    </Button>
                  </Col>
                  <Col className="text-right">
                    <Button color="primary" onClick={() => this.confirmarAlteracaoAvaliacaoTurma()}>
                      Alterar
                    </Button>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </>
    );
  }

  render() {
    return (
      <div className="content">
        {!this.state.erro
          ? this.conteudoPagina()
          : <Card>
            <div align="center" style={{ margin: 50 }}>
              Erro ao buscar informações do turma
            </div>
          </Card>
        }
        <LoaderModal
          isOpen={this.state.showLoaderModal}
          text={this.state.loaderModalText} />
        <ConfirmTypingModal
          isOpen={this.state.showConfirmModal}
          callback={(senha) => {
            this.setState({ showConfirmModal: false });
            if (senha) {
              this.alterarAvaliacoesTurma(senha);
            } else {
              this.setState({ turmaSelecionada: null });
            }
          }}
          deletionInstruction='Confirme que você quer alterar as avaliações da turma digitando a sua senha no campo abaixo'
          deletionText={''}
          buttonText={'Alterar'}
          deletionWarning='A alteração das avaliações de uma turma acarretará a exclusão de todas as avaliações lançadas pelos professores nessa turma.' />
      </div>
    );
  }
}

export default withRouter(AlterarAvaliacoesTurma);